<template>
<!--  自助放款-->
  <div class="selfHelpLoan">
    <el-card style="width: 60%;margin:10px auto;line-height: 60px;" v-show="bankFlag == 2">
      <div style="float: left">
        <div style="display: flex">
          <img src="../assets/img/yl.png"  style=";height: 60px;margin-right: 5%"/>
          <div style="white-space: nowrap;margin-right: 10%">{{bankName}}</div>
          <div style="width: 300px">{{bankCard}}</div>
        </div>
      </div>
      <div style="float: right;">
        <p class="text-blue" @click="changeBank" style="cursor: pointer">更换银行卡</p>
      </div>

    </el-card>
    <el-card style="width: 60%;margin:10px auto;line-height: 60px;" v-show="bankFlag == 1">
      <div style="float: left">
        <div style="display: flex">
          <img src="../assets/img/yl.png"  style=";height: 60px;margin-right: 5%"/>
        </div>
      </div>
      <div style="float: right;">
        <p class="text-blue" @click="changeBank" style="cursor: pointer">绑定银行卡</p>
      </div>

    </el-card>
    <el-card style="width: 60%;margin:10px auto;line-height: 60px;">

      <el-form :inline="true"  class="demo-form-inline">
        <el-form-item label="开单时间">
          <el-date-picker
                  size="mini"
                  v-model="date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  :default-time="['00:00:00','23:59:59']"
                  end-placeholder="结束日期"
                  value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="运单编号">
          <el-input type="text" size="mini" v-model="orderNo"/>
        </el-form-item>
        <el-form-item label="">
          <el-radio-group v-model="tabId">
            <el-radio :label="1">可操作运单</el-radio>
            <el-radio :label="2">待放款运单</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="">
          <el-button  size="mini" type="primary" @click="query()">查询</el-button>
        </el-form-item>

      </el-form>
      <!--<div class="pull-left" style="display: flex">-->
        <!--<div>-->
          <!--<label class="label-font-down">开单时间</label>-->
          <!--<el-date-picker-->
              <!--size="mini"-->
              <!--v-model="date"-->
              <!--type="daterange"-->
              <!--range-separator="至"-->
              <!--start-placeholder="开始日期"-->
              <!--:default-time="['00:00:00','23:59:59']"-->
              <!--end-placeholder="结束日期"-->
              <!--value-format="timestamp"-->
          <!--&gt;-->
          <!--</el-date-picker>-->
        <!--</div>-->
        <!--<div >-->
          <!--<label class="label-font-down">运单编号</label>-->
          <!--<el-input type="text" size="mini" v-model="orderNo"/>-->
        <!--</div>-->
        <!--<div style="margin-left: 15px;">-->
          <!--<el-radio-group v-model="tabId">-->
            <!--<el-radio :label="1">可操作运单</el-radio>-->
            <!--<el-radio :label="2">待放款运单</el-radio>-->
          <!--</el-radio-group>-->
        <!--</div>-->

      <!--</div>-->
      <!--<div class="pull-right">-->
        <!--<el-button  size="mini" type="primary" @click="query()">查询</el-button>-->
      <!--</div>-->

    </el-card>
    <div style="min-height: 500px;width: 100%;" v-loading="loading"  :class="payment.length === 0 ? 'pic' : ''" v-show="isPending == true">
      <el-row class="contentRow">
        <el-col :span="8" v-for="item in payment" :key="item.index" >
          <el-card class="box-card" style="padding: 20px 0 20px 0">
            <div style="margin-bottom: 25px">
              <span style="font-size: 15px;color: #2b3f69;">运单号:&nbsp;{{item.orderNo}}</span>
<!--              <el-checkbox-group v-model="checkArr" @change="handleChecked" style="float: right">-->
<!--                <el-checkbox :label="item.id">{{value}}</el-checkbox>-->
<!--              </el-checkbox-group>-->
              <span style="float: right;font-weight: bold;color: red;">{{item.statusValue}}</span>
            </div>
            <div style="margin:15px 0">开单时间:
              <span class="pull-right">{{updateDate(item.createTime)}}</span>
            </div>
            <div style="margin:15px 0">签收时间:
              <span class="pull-right">{{updateDate(item.signTime)}}</span>
            </div>
            <div style="margin:15px 0">收货人:
              <span class="pull-right">{{item.receiveUserName}}</span>
            </div>
            <div style="margin:15px 0">货物名称:
              <span class="pull-right">{{item.goodsName}}</span>
            </div>
            <div style="margin-top:15px">待入账金额:
              <span class="pull-right red-text">¥{{item.newInsteadGoodsFee}}</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div style="min-height: 500px;width:100%;" v-loading="loading"  :class="payment.length === 0 ? 'pic' : ''" v-show="isPending == false">
      <el-row class="contentRow">
        <el-col :span="8" v-for="item in payment" :key="item.index" >
          <el-card class="box-card" style="padding: 20px 0 20px 0">
            <div style="margin-bottom: 25px">
              <span style="font-size: 15px;color: #2b3f69;">运单号:&nbsp;{{item.orderNo}}</span>
              <el-checkbox-group v-model="checkArr" @change="handleChecked" style="float: right">
                <el-checkbox :label="item.id">{{value}}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div style="margin:15px 0">开单时间:
              <span class="pull-right">{{updateDate(item.createTime)}}</span>
            </div>
            <div style="margin:15px 0">签收时间:
              <span class="pull-right">{{updateDate(item.signTime)}}</span>
            </div>
            <div style="margin:15px 0">收货人:
              <span class="pull-right">{{item.receiveUserName}}</span>
            </div>
            <div style="margin:15px 0">货物名称:
              <span class="pull-right">{{item.goodsName}}</span>
            </div>
            <div style="margin-top:15px">金额:
              <span class="pull-right red-text">¥{{item.newInsteadGoodsFee}}</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div style="position: fixed;bottom: 0px;width: 60%;left: 20%;background-color: #ffffff;padding-bottom: 10px;z-index: 1000" v-show="isPending == false">
      <el-card style="margin:10px auto;line-height: 60px;">
      <div style="float: left">
        <el-checkbox :checked="isCheckedAll" v-model="checkAllFlag" @change="checkAll" >全选</el-checkbox>
        已选<span class="red-text">{{count}}</span>个运单，合计：
        <span class="red-text">￥{{totalFee}}</span>
      </div>
      <div class="pull-right">
        <el-button type="primary" size="mini" @click="loanOrders">放款</el-button>
      </div>
      </el-card>
    </div>

    <div style="position: fixed;bottom: 0px;width: 60%;left: 20%;background-color: #ffffff;padding-bottom: 10px;z-index: 1000" v-show="isPending == true">
      <el-card style="margin:10px auto;line-height: 60px;">
      <div style="float: left">
        <el-checkbox :checked="isCheckedAll" v-model="checkAllFlag" @change="checkAll">全选</el-checkbox>
        待签收入账总额<span class="red-text">{{totalFee}}</span>元
        共<span class="red-text">{{count}}</span>票
      </div>
      </el-card>
    </div>

    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <div style="height: 300px;overflow: auto;">
        <div v-for="(item,index) in groupData.groupList" :key="index" style="padding:2% 5%;font-size: 15px;">
          <div style="padding-bottom: 3px;font-weight: bold">{{item.orgName}}</div>
          <div>
            <el-row>
              <el-col :span="12">票数:{{item.total}}</el-col>
              <el-col :span="12">应入账:{{item.yrz}}元</el-col>
            </el-row>
            <el-row>
              <el-col :span="12">代收:{{item.dsje}}元</el-col>
              <el-col :span="12">手续费:{{item.sxf}}元</el-col>
            </el-row>
          </div>
        </div>
        <div style="padding:1% 5%;font-size: 15px;position:absolute;bottom: 30px">
          本次放款分为<span class="red-text">{{groupData.sumTotal}}</span>笔批次入账,共计<span class="red-text">{{groupData.sumTotalFee}}</span>元
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmLoan()">确 定</el-button>
  </span>
    </el-dialog>

    <el-dialog
        :visible.sync="orderSuccessDialogVisible"
        width="25%"
        center
        style="margin-top: 150px"
    >
      <div class="padding20 text-center">
        <img style="width: 80px;height: 80px" src="../assets/img/completeorder.png">
        <h3 class="text-center">恭喜您，放款成功</h3>
        <div style="margin:20px 80px;">
          <div style="font-weight: bold;font-size: 15px;display: flex;">订单信息</div>
          <div style="display: flex;margin-top:10px;justify-content: space-between">
            <p>运单数（个）：</p><div>{{groupData.sumTotal}}</div>
          </div>
          <div style="display: flex;margin-top:10px;justify-content: space-between">
            <p>合计金额：</p><div>{{groupData.sumTotalFee}}</div>
          </div>
          <el-button @click="orderSuccessDialogVisible = false" type="primary" style="margin-top:10px;" >确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { findLoanList,findCustomerBankInfo,groupByOrders,loanOrders} from "../api/selfHelpLoan";

export default {
  data() {
    return {
      isPending:false,//是否是待入账
      orderSuccessDialogVisible:false,
      loading: false,
      dialogVisible:false,
      value:'',
      checkArr:[],//选中数组
      isCheckedAll:false,//全选判断标识
      checkAllFlag:false,
      checkedAllArr:[],//全选数组
      count:0,//已选运单数
      totalFee:0,//合计
      payment:[],
      tabId:1,
      orderNo:'',
      date:[this.toTimeStamp(this.firstDay()), this.getEndDayTime()],
      bankFlag:1,//是否绑定银行卡 2是
      bankName:'',//银行名称
      bankCard:'',//银行卡号
      currentIndex: 0,
      queryCondition: [{
        id: '1',
        start: '',
        end: '',
        serialNo: '',
        status: '可操作运单',
        pageNum: 1,
        pageSize: 15,
        paymentList: [],
        hasMore: false,
        total: 0,
        numberPens: 0,
        name:1
      },
        {
          id: '2',
          start: '',
          end: '',
          serialNo: '',
          status: '待放款运单',
          pageNum: 1,
          pageSize: 15,
          paymentList: [],
          hasMore: false,
          total: 0,
          numberPens: 0,
          name:2
        }],
      orderIds:'',
      groupData:{
        groupList:[],
        sumTotal:'',
        sumTotalFee:'',
      },
    };
  },
  methods: {
    //日期格式转时间戳
    toTimeStamp(times) {
      if (times === '' || times === null || !times) {
        return '';
      }
      let dates = times.split("-"),
          datex = new Date(dates[0], dates[1] - 1, dates[2], 0, 0, 0);
      return datex = Date.parse(datex);
    },
    //当月第一天日期
    firstDay() {
      let date_ = new Date();
      let year = date_.getFullYear();
      let month = date_.getMonth() + 1;
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      let firstDate = year + '-' + month + '-01';
      return firstDate
    },

    //当天的结束时间戳
    getEndDayTime() {
      var date = new Date();
      var todayYear = date.getFullYear();
      var todayMonth = date.getMonth();
      var today = date.getDate();
      //获取当天的开始时间
      new Date(todayYear, todayMonth, today, '00', '00', '00');
      //获取当天的结束时间
      return new Date(todayYear, todayMonth, today, '23', '59', '59').getTime();
      //包括当前时间，三天内
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    //放款前分组提示
    loanOrders() {
      if(this.bankFlag !==2){this.$message.error('请先绑银行卡!');return false;}
      this.orderIds = [];
      if(!this.checkArr.length){this.$message.error('请选择要放款的数据！');return false}
      groupByOrders({orderIds:this.checkArr.join(',')}).then((res) => {
        if (res.code === 200) {
          this.dialogVisible = true;
          this.groupData.groupList = res.data.groupList;
          this.groupData.sumTotal = res.data.sumTotal;
          this.groupData.sumTotalFee = res.data.sumTotalFee;
        }else{
          this.$message.error(res.msg);
          return false;
        }
      })
    },
    //放款
    confirmLoan(){
      loanOrders({orderIds: this.checkArr.join(',')}).then((res) => {
        if (res.code === 200) {
          this.orderIds = ''
          this.orderSuccessDialogVisible = true;
          this.dialogVisible = false;
          this.query();
          this.checkArr = []
        }
      })
    },
    //更换银行卡
    changeBank(){
      this.$router.push({ path: 'changeInformation' })
    },
    //单选
    handleChecked(){
      this.isCheckedAll = this.checkArr.length == this.payment.length;
      this.checkAllFlag = this.checkArr.length == this.payment.length;
      this.count = this.checkArr.length
      this.totalFee = 0
      this.checkArr.forEach(val=>{
        let fee = this.payment.find(item=>item.id===val).newInsteadGoodsFee
        this.totalFee = this.totalFee+fee;
      })

    },
    // 全选
    checkAll(){
      this.checkArr = this.checkAllFlag ? this.checkedAllArr : [];
      this.count = this.checkArr.length
      this.totalFee = 0
      this.checkArr.forEach(val=>{
        let fee = this.payment.find(item=>item.id===val).newInsteadGoodsFee
        this.totalFee = this.totalFee+fee;
      })
    },
    query(){
      this.checkArr = [];
      this.count = 0
      this.totalFee = 0

      console.log(this.loading)
      if(this.tabId == 2){
        this.isPending = true
      }
      if(this.tabId == 1){
        this.isPending = false
      }
      if(!this.date || this.date.length ==0){
        this.$message.error('请选择开单时间');
        return false
      }else{
        this.loading = true;
        this.getLoanList();
      }
    },
    //查找绑定的银行卡
    getCustomerBankInfo() {
      findCustomerBankInfo().then((res) => {
        if (res.code === 200) {
          if(res.data.bankFlag ==2){//有
            this.bankFlag = res.data.bankFlag;
            this.bankName = res.data.bankName;
            this.bankCard = res.data.bankCard;
            var reg = /^(\d{4})\d+(\d{4})$/;
            this.bankCard = this.bankCard.replace(reg,"$1 **** **** $2") ;
          }else{//没有
            //this.$router.push({ path: 'changeInformation' })//跳转绑定银行卡
          }
        }
      })
    },
    getLoanList(){
      let params ={
        pageNum: 1,
        pageSize: 1000,
        type: this.tabId,
        startTime : this.date[0],
        endTime : this.date[1]+999,
      };
      findLoanList(params).then((res) => {
        if (res.code === 200) {
          if(res.data.list.length == 0){
            this.$message({
              message: "未查到数据",
              offset: 80,
              type: "success",
            });
          }
          this.checkedAllArr = [];
          this.payment = res.data.list;
          this.payment.forEach(val=>{
            this.checkedAllArr.push(val.id)
          })
          this.loading = false;
          console.log(this.loading)
        }
      })
    },
    updateDate(timeStamp) {
      if (!timeStamp || timeStamp === '0' || timeStamp === 0 || timeStamp === "null") return '';
      var date = new Date();
      date.setTime(timeStamp);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return timeStamp = y + '-' + m + '-' + d;
    }
  },
  mounted() {
    this.getCustomerBankInfo();
  }
};
</script>

 <style lang="scss" scoped>
   .contentRow{
     display:flex;justify-content: flex-start;padding-bottom: 100px;width: 60%;flex-wrap: wrap;margin: auto;
   }
 .margin-top-20 {
   margin-top: 20px;
 }
 /deep/ .el-dialog{border-radius: 8px}
 .text-center{text-align: center}
 /deep/.el-dialog__header {
   padding: 20px 20px 10px!important;
 }
 .padding20{padding: 20px}
 .text-blue{color: #409EFF}
 .red-text{color: #FB3A39}
 .pull-left{float:left}
 .pull-right{float: right}
 .selfHelpLoan {
   margin-top: 3%;
   display: flex;
   justify-content: center;
   flex-direction: column;
 }
 .box-card {
   width: 95%;
   margin:20px 0;
 }
 .label-font-down {
   margin: 5px 8px;
   font-size: 14px;
   font-family: Microsoft YaHei, Microsoft YaHei-Normal;
   font-weight: normal;
   text-align: LEFT;
   color: #333333;}
 .box-card div{
   //opacity: 0.7;
   font-size: 14px;
   font-family: PingFang SC, PingFang SC-Regular;
   font-weight: Regular;
   text-align: left;
   color: #2b3f69;
   line-height: 20px;
 }
 //::v-deep .el-loading-spinner {
 //  top: 330px;
 //}
 .pic{
   margin:0 auto;
   background-position:center;
   background-size:240px 180px;
   background-image: url("../assets/img/kong.png");
   text-align: center;
   width: 300px;height: 300px;
   background-color: #ffffff;border-radius: 8px;
   background-repeat: no-repeat;
 }
 .el-message {
   height: 100px !important;
   top: 120% !important;
   margin-top: 200px !important;
   z-index: 100000002 !important;
 }
 /deep/.el-card__body,.el-main{
   padding: 1px 20px 1px 20px!important;
 }
 /deep/.el-form--inline .el-form-item__content {
   display: inline-block;
   vertical-align: unset!important;
 }
</style>
