import request from "../utils/request";

//放款列表
export function findLoanList(params) {
    return request({
        url: "selfLoan/findLoanList",
        method: "post",
        params: params,
    });
}
//查找绑定的银行卡
export function findCustomerBankInfo(params) {
    return request({
        url: "selfLoan/findCustomerBankInfo",
        method: "post",
        params: params,
    });
}
//放款前分组提示
export function groupByOrders(params) {
    return request({
        url: "selfLoan/groupByOrders",
        method: "post",
        params: params,
    });
}
//放款
export function loanOrders(params) {
    return request({
        url: "selfLoan/loanOrders",
        method: "post",
        params: params,
    });
}